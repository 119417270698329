import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgStopSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_5_134)">
      <path
        d="M5.00001 5L8 7.99999M8 7.99999L11 11M8 7.99999L11 5M8 7.99999L5 11M4.76923 15H11.2308L15 11.2308V4.76923L11.2308 1H4.76923L1 4.76923V11.2308L4.76923 15Z"
        stroke={props.color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5_134">
        <rect width={16} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
SvgStopSmall.displayName = 'SvgStopSmall';
const StopSmall = withForgeIconProps(SvgStopSmall, {
  name: 'Stop',
  usage: 'Use to indicate the need to stop or discontinue an action',
  related: 'stop, discontinue',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'small',
});
export default StopSmall;
