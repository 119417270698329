import { CSSProperties, ReactElement, Ref } from 'react';
import Portal from '../../Portal';
import { forgeClassHelper } from '../../utils/classes';

interface CollapsibleSectionPanelProps {
  floatingStyles: CSSProperties;
  inputField: ReactElement;
  portalRef: Ref<HTMLElement>;
  selectionPanel: ReactElement;
  setFloating: (node: HTMLElement | null) => void;
  showSelectionPanel: boolean;
}

const classes = forgeClassHelper('high-volume-multiselect');

const CollapsibleSectionPanel = ({
  floatingStyles,
  inputField,
  portalRef,
  selectionPanel,
  setFloating,
  showSelectionPanel,
}: CollapsibleSectionPanelProps): JSX.Element => {
  return (
    <>
      {inputField}
      {showSelectionPanel && (
        <Portal ref={portalRef}>
          <div
            {...classes('collapsible-container')}
            ref={setFloating}
            style={floatingStyles ? { ...floatingStyles } : undefined}
          >
            {selectionPanel}
          </div>
        </Portal>
      )}
    </>
  );
};

export default CollapsibleSectionPanel;
