import { ReactElement } from 'react';
import Checkbox from '../../Checkbox';
import { forgeClassHelper } from '../../utils/classes';

const classes = forgeClassHelper('high-volume-multiselect');

export interface OptionProps {
  value: string;
  id: string;
  label: string;
  disabled: boolean | undefined;
  selectedMap: Record<string, boolean>;
  toggleOption: (arg: string) => React.ChangeEventHandler<HTMLDivElement>;
  keyDownToggleOption: (arg: string) => React.KeyboardEventHandler<HTMLDivElement>;
}

const Option = ({
  value,
  id,
  label,
  disabled,
  selectedMap,
  toggleOption,
  keyDownToggleOption,
}: OptionProps): ReactElement => {
  return (
    <div {...classes('option-container')}>
      <Checkbox
        key={value}
        id={`${id}Checkbox${value}`}
        valueAttribute={value}
        description={label}
        disabled={disabled}
        checked={selectedMap[value] ?? false}
        onChange={toggleOption(value)}
        tabIndex={disabled ? -1 : 0}
        onKeyDown={keyDownToggleOption(value)}
      />
    </div>
  );
};

export default Option;
