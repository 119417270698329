import { flip } from '@floating-ui/dom';
import { useFloating } from '@floating-ui/react';
import PropTypes from 'prop-types';
import { Ref, useEffect, useImperativeHandle, useMemo, useRef, useState, type WeakValidationMap } from 'react';
import Input from '../Input';
import useFocusLost from '../useFocusLost';
import { forgeClassHelper } from '../utils/classes';
import forwardRefToProps from '../utils/forwardRefToProps';
import { SemiPartial } from '../utils/types';
import CollapsibleSelectionPanel from './components/CollapsibleSelectionPanel';
import SelectionPanel from './components/SelectionPanel';
import CloseSmall from '@athena/forge-icons/dist/CloseSmall';
import ExpandSmall from '@athena/forge-icons/dist/ExpandSmall';

const classes = forgeClassHelper('high-volume-multiselect');

/** inspiration taken from Select's StructuredOption type */
export interface HighVolumeMultiSelectOption {
  /** The value of the option when selected */
  value: string;
  /** The text to display to the user */
  label: string;
  /** Option is visible, but not selectable */
  disabled?: boolean;
}

/**
 * Options read in props have the "label" and "disabled" fields as optional
 * This is different from StructuredOption internally where all fields are required
 */
type OptionType = SemiPartial<HighVolumeMultiSelectOption, 'label' | 'disabled'>;

type BulkSelectType = (event: React.MouseEvent<HTMLButtonElement | SVGSVGElement>, keys: string[]) => void;

export interface HighVolumeMultiSelectProps {
  /** Required id so multiple fields don't conflict with each other. */
  id: string;
  /**
   * List of options to display. Either an array of string, or an array of
   * objects of a specific shape. If an array of strings is used with the
   * `selected` prop, the string itself is used as the key for purposes of
   * selecting.
   */
  options: ReadonlyArray<OptionType> | ReadonlyArray<string>;

  /** Array of keys into the options list. For use as a controlled component. */
  selected?: ReadonlyArray<string>;

  /** Event fired when the checkboxes in the left pane are interacted with. */
  onToggle?: (event: React.ChangeEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>, key: string) => void;

  /**
   * Event fired when the "select all" checkbox is interacted with. Provides the current
   * filtered view as a list of keys.
   */
  onSelectAll?: BulkSelectType;

  /** Event fired when the X button on a removable tag is clicked. */
  onRemove?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined, key: string) => void;

  /** Event fired when the "clear all" button is clicked. */
  onClearAll?: BulkSelectType;
  /** Ref to top level div */
  ref?: Ref<HTMLDivElement>;
  /** Indicates if a selection is required */
  required?: boolean;
  /**
   * Shorthand function for use when used as a controlled component. If any of
   * the four events fire, this handler is also called with the list of modified
   * keys and the new state they should be set to.
   */
  setKeys?: (keys: string[], state: boolean) => void;
  /**
   * Boolean flag indicating whether the component is collapsible. If true, a collapse/expand
   * control is displayed and the component can be collapsed to save space.
   */
  isCollapsible?: boolean;
}

interface HighVolumeMultiSelectComponentProps extends HighVolumeMultiSelectProps {
  /** Ref to top level div */
  forwardedRef?: Ref<HTMLDivElement>;
}

/** A MultiSelect variant that allows users to view and select options from high volumes of data */
const HighVolumeMultiSelect = ({
  forwardedRef,
  id,
  onClearAll,
  onRemove,
  onToggle,
  onSelectAll,
  options: rawData,
  required = false,
  selected: rawSelected,
  setKeys,
  isCollapsible = false,
}: HighVolumeMultiSelectComponentProps): JSX.Element => {
  /** keeping track of this to know if we're flipflopping between controlled and not */
  const controlled = !!rawSelected;
  const prevControlled = useRef(controlled);
  const containerRef = useRef<HTMLDivElement>(null);
  const portalRef = useRef<HTMLDivElement>(null);
  useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(forwardedRef, () => containerRef.current);

  useEffect(() => {
    if (controlled !== prevControlled.current) {
      // eslint-disable-next-line no-console
      console.error(
        'HighVolumeMultiSelect shifting between controlled and uncontrolled. This may cause bugs. Please see https://react.dev/reference/react-dom/components/textarea#im-getting-an-error-a-component-is-changing-an-uncontrolled-input-to-be-controlled'
      );
    }
    prevControlled.current = controlled;
  }, [controlled]);

  const formattedOptions = useMemo(() => {
    const newOptions: { [OptionKey: HighVolumeMultiSelectOption['value']]: HighVolumeMultiSelectOption } = {};

    rawData.forEach((option) => {
      if (typeof option === 'string') {
        newOptions[option] = {
          value: option,
          label: option,
          disabled: false,
        };
      } else {
        const key = option.value;
        newOptions[key] = {
          value: key,
          label: option.label ?? key,
          disabled: !!option.disabled,
        };
      }
    });

    return newOptions;
  }, [rawData]);

  const [selectedMap, setSelectedMap] = useState<Record<string, boolean>>({});
  const [filterTerm, setFilterTerm] = useState('');
  const [inputHasFocus, setInputHasFocus] = useState(false);
  const [showSelectionPanel, setShowSelectionPanel] = useState(false);

  /**
   * We use the same comparison function for both selected and filtered option lists
   * using the comparison operators to maintain the UTF16 comparison of default Array.sort()
   * This should mimic the default string sorting in perl
   */
  const compareFn: (a: HighVolumeMultiSelectOption, b: HighVolumeMultiSelectOption) => number = (
    { label: a },
    { label: b }
  ) => (a > b ? 1 : a < b ? -1 : 0);

  /**
   * Memoized slices of state to cut down on all the array operations as much as possible during rerenders.
   *
   * Utilized to apply/remove filtering against formattedOptions to correctly display all available options
   */
  const memoizedOptions = useMemo(() => {
    const pattern = new RegExp(filterTerm, 'i');
    return Object.values(formattedOptions)
      .filter(({ label }) => label.match(pattern))
      .sort(compareFn);
  }, [filterTerm, formattedOptions]);

  const selectedOptions = useMemo(
    () =>
      Object.values(formattedOptions)
        .filter(({ value }) => selectedMap[value])
        .sort(compareFn),
    [formattedOptions, selectedMap]
  );

  const allSelected = useMemo(
    () =>
      memoizedOptions.length ? memoizedOptions.every(({ disabled, value }) => disabled || selectedMap[value]) : false,
    [memoizedOptions, selectedMap]
  );

  /**
   * Check if anything is selected that is allowed to be deselected
   *
   * This is subtly different than just `allSelected || someSelected`
   * In the case that every entry is disabled, this should be false, despite allSelected being true.
   * We also care about every value, not just the ones that are in the memoizedOptions list
   */
  const canClearAll = useMemo(() => !!selectedOptions.filter(({ disabled }) => !disabled).length, [selectedOptions]);

  /** memoized list of all selectable options i.e., all non-disabled options */
  const enabledOptions = useMemo(() => memoizedOptions.filter((opt) => opt.disabled === false), [memoizedOptions]);
  /**
   * Selected state management.
   *
   * We want to rebuild from the rawSelected if controlled.
   * If uncontrolled, clean up any stale selected data if the options have changed
   */
  useEffect(() => {
    if (controlled) {
      setSelectedMap(Object.fromEntries(rawSelected.map((k) => [k, true])));
    } else {
      setSelectedMap((oldSelectedMap) =>
        Object.fromEntries(Object.entries(oldSelectedMap).filter(([k]) => !!formattedOptions[k]))
      );
    }
  }, [controlled, formattedOptions, rawSelected]);

  const setOptionsHelper = (keys: string[], selected: boolean): void => {
    setKeys?.(keys, selected);
    if (controlled) return;

    setSelectedMap((oldSelectedMap) => {
      const updatedKeys = Object.fromEntries(keys.map((k) => [k, selected]));
      return { ...oldSelectedMap, ...updatedKeys };
    });
  };

  const handleInputClick = (selectedOptions: HighVolumeMultiSelectOption[]): void => {
    if (selectedOptions.length > 0 || !!filterTerm) {
      setShowSelectionPanel(true);
    } else setShowSelectionPanel(!showSelectionPanel);
  };

  /** handles bulk selection of all options in Options panel */
  const handleSelectAll = (e: React.MouseEvent<HTMLButtonElement>): void => {
    const keys = memoizedOptions.filter(({ disabled }) => !disabled).map(({ value }) => value);
    onSelectAll?.(e, keys);
    setOptionsHelper(keys, true);
  };

  /** handles bulk deselection of all selections in Selected panel */
  const handleClearAll = (e: React.MouseEvent<HTMLButtonElement | SVGSVGElement>): void => {
    const keys = selectedOptions.filter(({ disabled }) => !disabled).map(({ value }) => value);
    onClearAll?.(e, keys);
    setOptionsHelper(keys, false);
    if (isCollapsible) {
      setFilterTerm('');
      if (
        refs.reference.current &&
        'focus' in refs.reference.current &&
        typeof refs.reference.current.focus === 'function'
      ) {
        refs.reference.current.focus();
      }
    }
  };

  /*
   * toggleOption; keyDownToggleOption; handleTagRemoval
   *
   * Identifiable information is hard to obtain from the events on the Checkboxes and Tags.
   * So, we use currying to close the event handler over the key
   *
   */
  /** Toggles selected status of an individual option in the Options panel */
  const toggleOption: (key: string) => React.ChangeEventHandler<HTMLDivElement> = (key) => (e) => {
    onToggle?.(e, key);
    setOptionsHelper([key], !selectedMap[key]);
  };

  /** handles keydown events on an individual option */
  const keyDownToggleOption: (key: string) => React.KeyboardEventHandler<HTMLDivElement> = (key) => (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();

      onToggle?.(e, key);
      setOptionsHelper([key], !selectedMap[key]);
    }

    // if a user is interacting with an option via 'Backspace', only execute if the option is selected
    if (e.key === 'Backspace') {
      e.preventDefault();

      if (selectedMap[key]) {
        onToggle?.(e, key);
        setOptionsHelper([key], false);
      }
    }
  };

  /** handles deselection of an individual selection from Selected panel */
  const handleTagRemoval: (key: string) => (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void =
    (key) => (e) => {
      onRemove?.(e, key);
      setOptionsHelper([key], false);
    };

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    middleware: [flip()],
  });

  useFocusLost(() => {
    setShowSelectionPanel(false);
    isCollapsible && setFilterTerm('');
  }, [containerRef, portalRef]);

  const selectionPanel = (
    <SelectionPanel
      allSelected={allSelected}
      canClearAll={canClearAll}
      enabledOptions={enabledOptions}
      handleClearAll={handleClearAll}
      handleSelectAll={handleSelectAll}
      handleTagRemoval={handleTagRemoval}
      id={id}
      inputField={
        !isCollapsible && (
          <Input
            {...classes('filter')}
            value={filterTerm}
            onChange={(e) => setFilterTerm(e.target.value)}
            placeholder="Search"
            aria-label="Filter"
          />
        )
      }
      keyDownToggleOption={keyDownToggleOption}
      memoizedOptions={memoizedOptions}
      required={required}
      selectedMap={selectedMap}
      selectedOptions={selectedOptions}
      toggleOption={toggleOption}
    />
  );

  return (
    <div
      {...classes({ element: 'container', modifiers: { collapsed: isCollapsible, expanded: !isCollapsible } })}
      ref={containerRef}
    >
      {isCollapsible ? (
        <CollapsibleSelectionPanel
          floatingStyles={floatingStyles}
          inputField={
            <Input
              {...classes('filter')}
              value={
                (filterTerm && showSelectionPanel) || inputHasFocus
                  ? filterTerm
                  : selectedOptions.length > 0
                    ? `${selectedOptions.length} Selected`
                    : ''
              }
              onChange={(e) => {
                if (inputHasFocus) setShowSelectionPanel(true);
                setFilterTerm(e.target.value);
              }}
              placeholder="Type or Select"
              aria-label="Filter"
              onClick={() => handleInputClick(selectedOptions)}
              onFocus={() => setInputHasFocus(true)}
              onBlur={() => setInputHasFocus(false)}
              ref={refs.setReference}
              required={required}
            >
              {selectedOptions.length > 0 && !inputHasFocus && !showSelectionPanel && (
                <>
                  <CloseSmall {...classes({ element: 'clear-icon' })} onClick={handleClearAll} size="12px" />
                  <span {...classes('pipe')}>|</span>
                </>
              )}

              <ExpandSmall {...classes({ element: 'expand-icon' })} size="12px" />
            </Input>
          }
          portalRef={portalRef}
          selectionPanel={selectionPanel}
          setFloating={refs.setFloating}
          showSelectionPanel={showSelectionPanel}
        />
      ) : (
        selectionPanel
      )}
    </div>
  );
};

export const highVolumeMultiSelectPropTypes: WeakValidationMap<HighVolumeMultiSelectComponentProps> = {
  /** Required id so multiple fields don't conflict with each other. */
  id: PropTypes.string.isRequired,

  /**
   * List of options to display. Either an array of string, or an array of
   * objects of a specific shape. If an array of strings is used with the
   * `selected` prop, the string itself is used as the key for purposes of
   * selecting.
   */
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
      }).isRequired
    ),
    PropTypes.arrayOf(PropTypes.string.isRequired),
  ]).isRequired,

  /** Array of keys into the options list. For use as a controlled component. */
  selected: PropTypes.arrayOf(PropTypes.string.isRequired),

  /** Event fired when the checkboxes in the left pane are interacted with. */
  onToggle: PropTypes.func,

  /** Event fired when the "Select All" button is interacted with. */
  onSelectAll: PropTypes.func,

  /** Event fired when the X button on a removable tag is clicked. */
  onRemove: PropTypes.func,

  /** Event fired when the "clear all" button is clicked. */
  onClearAll: PropTypes.func,
  /** Indicates if a selection is required */
  required: PropTypes.bool,
  /**
   * Shorthand function for use when used as a controlled component. If any of
   * the four events fire, this handler is also called with the list of modified
   * keys and the new state they should be set to.
   */
  setKeys: PropTypes.func,
  /**
   * Boolean flag indicating whether the component is collapsible. If true, a collapse/expand
   * control is displayed and the component can be collapsed to save space.
   */
  isCollapsible: PropTypes.bool,
};

HighVolumeMultiSelect.propTypes = highVolumeMultiSelectPropTypes;
HighVolumeMultiSelect.displayName = 'HighVolumeMultiSelect';

export default forwardRefToProps(HighVolumeMultiSelect);
