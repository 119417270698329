import React, { ReactElement, ReactNode } from 'react';
import Button from '../../Button';
import { forgeClassHelper } from '../../utils/classes';
import { HighVolumeMultiSelectOption } from '../HighVolumeMultiSelect';
import Options from './Options';
import SelectedOptions from './SelectedOptions';

const classes = forgeClassHelper('high-volume-multiselect');

interface SelectionPanelProps {
  allSelected: boolean;
  canClearAll: boolean;
  enabledOptions: HighVolumeMultiSelectOption[];
  handleClearAll: (e: React.MouseEvent<HTMLButtonElement | SVGSVGElement>) => void;
  handleSelectAll: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleTagRemoval: (key: string) => (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  id: string;
  inputField?: ReactNode;
  keyDownToggleOption: (key: string) => React.KeyboardEventHandler<HTMLDivElement>;
  memoizedOptions: HighVolumeMultiSelectOption[];
  required: boolean;
  selectedMap: Record<string, boolean>;
  selectedOptions: HighVolumeMultiSelectOption[];
  toggleOption: (key: string) => React.ChangeEventHandler<HTMLDivElement>;
}
const SelectionPanel = ({
  allSelected,
  canClearAll,
  enabledOptions,
  handleClearAll,
  handleSelectAll,
  handleTagRemoval,
  id,
  inputField,
  keyDownToggleOption,
  memoizedOptions,
  required,
  selectedMap,
  selectedOptions,
  toggleOption,
}: SelectionPanelProps): ReactElement => {
  return (
    <div {...classes({ states: { required } })} id={id}>
      <div {...classes('search-side')}>
        <div {...classes('header')}>
          {inputField}
          <Button
            {...classes('select-all-button')}
            variant="tertiary"
            disabled={allSelected || !memoizedOptions.length}
            onClick={handleSelectAll}
            id={`${id}CheckboxSelectAll`}
            tabIndex={allSelected || !memoizedOptions.length ? -1 : 0}
          >
            {`Select All (${enabledOptions.length})`}
          </Button>
        </div>
        <Options
          memoizedOptions={memoizedOptions}
          id={id}
          selectedMap={selectedMap}
          toggleOption={toggleOption}
          keyDownToggleOption={keyDownToggleOption}
        />
      </div>
      <div {...classes('selected-side')}>
        <div {...classes('selected-side-header')}>
          <span {...classes('label', selectedOptions.length ? '' : 'disabled')}>
            Selected ({selectedOptions.length})
          </span>
          <Button
            variant="tertiary"
            disabled={!canClearAll}
            onClick={handleClearAll}
            tabIndex={canClearAll ? 0 : -1}
            {...classes('hvms-clear-all')}
          >
            Clear All
          </Button>
        </div>
        <SelectedOptions
          selectedOptions={selectedOptions}
          handleTagRemoval={handleTagRemoval}
          keyDownToggleOption={keyDownToggleOption}
        />
      </div>
    </div>
  );
};

export default SelectionPanel;
