import MenuItem from '../../../MenuItem';
import Menu from '../../../Menu';
import Button from '../../../Button';
import DownloadSmall from '@athena/forge-icons/dist/DownloadSmall';
import DownloadLarge from '@athena/forge-icons/dist/DownloadLarge';
import dayjs from 'dayjs';
import { DataTableStore } from '../../store/stateTypes';
import { delay } from '../../utils/delay';
import { AwaitableModal } from '../../alerts/AwaitableModal';

type ServerSideDownloadCsvButtonProps<RowData> = {
  store: DataTableStore<RowData>;
};

export function ServerSideDownloadCsvButton<RowData>({
  store,
}: ServerSideDownloadCsvButtonProps<RowData>): JSX.Element {
  const dispatch = store.useDispatch();

  // ==================================
  // Table
  // ==================================
  const tableLayout = store.useSelector((s) => s.tableLayout);

  // ==================================
  // CSV
  // ==================================
  const DownloadIcon = { medium: DownloadLarge, compact: DownloadSmall }[tableLayout];
  const csvDownload = store.useSelector((s) => s.csvDownload);
  const fetchDataArgs = store.useSelector((s) => s.serverSideFetchRowDataArgs);
  const csvDownloadFileName = `${csvDownload.downloadFilePrefix}-${dayjs().format('YYYYMMDD')}.csv`;
  const csvDisableDownload = store.useSelector((s) => {
    const isRowEditing = Object.values(s.rows).some((row) => row.rowStatus === 'edit');
    const isLoading = s.externalLoader.show || s.loader.show;

    // Disable CSV Download when row is editing or loading
    return isRowEditing || isLoading;
  });

  // CSV Download is disabled
  if (!csvDownload.showButton || csvDownload.fetchCSV === undefined) {
    return <></>;
  }

  // ==================================
  // Rows
  // ==================================
  const hasFilteredRows =
    !fetchDataArgs.includeDeletedRows ||
    fetchDataArgs.quickFilterQueryParams.length !== 0 ||
    fetchDataArgs.dataFilterQueryParams.length !== 0 ||
    fetchDataArgs.columnFilterQueryParams.length !== 0 ||
    fetchDataArgs.searchText.length !== 0;

  // ==================================
  // Download CSV from Server
  // ==================================
  const downloadCsvFromServer = async ({ allRows }: { allRows: boolean }): Promise<void> => {
    if (csvDownload.fetchCSV === undefined) {
      return;
    }

    dispatch(function show_loader(s) {
      s.loader.show = true;
    });
    await delay(10);

    try {
      const res = await csvDownload.fetchCSV(
        allRows
          ? {
              includeDeletedRows: true,
              pageIndex: 0,
              pageSize: -1,
              dataFilterQueryParams: [],
              quickFilterQueryParams: [],
              columnFilterQueryParams: [],
              searchText: '',
              sortByColumnId: fetchDataArgs.sortByColumnId,
              sortOrder: fetchDataArgs.sortOrder,
            }
          : { ...fetchDataArgs, pageIndex: 0, pageSize: -1 }
      );
      const url = window.URL.createObjectURL(res.blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = csvDownloadFileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (e) {
      await AwaitableModal(dispatch, {
        alertType: 'attention',
        disableClose: true,
        headerText: 'Generate CSV Failed',
        children: (e as Error).message,
      });
    }

    dispatch(function hide_loader(s) {
      s.loader.show = false;
    });
  };

  // Don't display menu in any case if enableDownloadOptions is false
  const noMenuOptions = !csvDownload.enableDownloadOptions;
  if (noMenuOptions) {
    return (
      <Button
        text={csvDownload.buttonText}
        icon={DownloadIcon}
        variant="tertiary"
        disabled={csvDisableDownload}
        onClick={() => downloadCsvFromServer({ allRows: !hasFilteredRows })}
      />
    );
  }

  // Display button if no filter applied
  if (!hasFilteredRows) {
    return (
      <Button
        text={csvDownload.buttonText}
        icon={DownloadIcon}
        variant="tertiary"
        disabled={csvDisableDownload}
        onClick={() => downloadCsvFromServer({ allRows: true })}
      />
    );
  }

  // Display Menu with "Filtered Data" and "All Data" option if filters applied
  return (
    <Menu
      hideLabel
      label={csvDownload.buttonText}
      trigger={
        <Button
          icon={DownloadIcon} // prettier-break-line
          text={csvDownload.buttonText}
          variant="tertiary"
          disabled={csvDisableDownload}
        />
      }
    >
      <MenuItem>
        <Button
          text="Filtered Data"
          variant="tertiary"
          disabled={csvDisableDownload}
          onClick={() => downloadCsvFromServer({ allRows: false })}
        />
      </MenuItem>
      <MenuItem>
        <Button
          text="All Data"
          variant="tertiary"
          disabled={csvDisableDownload}
          onClick={() => downloadCsvFromServer({ allRows: true })}
        />
      </MenuItem>
    </Menu>
  );
}
