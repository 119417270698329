import { ModalProps } from '../../Modal';
import { PaginatorDisplayMode } from '../../Paginator/Paginator';
import { ToastProps } from '../../Toast';
import { TooltipProps } from '../../Tooltip';
import { InferAction, InferAsyncAction, InferDispatch, InferDispatchAsyncAction, Store } from '../../utils/createStore';
import { Prettify } from '../../utils/types';
import {
  DataTableAlertFunctions,
  DataTableFilter,
  AlertData,
  DataTableColumnFilterProps,
  DataTableHooksAndColumnId,
  DataTableHooksAndRowId,
  DataTableSortDirection,
  ModalAlertData,
  RowEmptyReason,
  ToastAlertData,
  DataTableDetermineCellTooltipArgs,
  DataTableCellTooltipProps,
} from '../utils/internalTypes';

export type DataTableStore<RowData> = Store<DataTableState<RowData>>;
export type DataTableDispatch<RowData> = InferDispatch<DataTableState<RowData>>;
export type DataTableDispatchAsyncAction<RowData> = InferDispatchAsyncAction<DataTableState<RowData>>;
export type DataTableAction<RowData> = InferAction<DataTableState<RowData>>;
export type DataTableAsyncAction<RowData> = InferAsyncAction<DataTableState<RowData>>;
export type DataTableHooks<RowData> = Prettify<
  Pick<DataTableStore<RowData>, 'useDispatch' | 'useSelector' | 'useDispatchAsyncAction'>
>;

export type DataTableFetchRowDataArgs = {
  pageIndex: number;
  pageSize: number;
  searchText: string;
  sortByColumnId: string;
  sortOrder: '' | 'asc' | 'desc';
  includeDeletedRows: boolean;
  quickFilterQueryParams: string[];
  dataFilterQueryParams: string[];
  columnFilterQueryParams: string[];
};

export type RowObject<RowData> = {
  rowId: number; // DataTable internal unique row id
  rowStatus: 'view' | 'edit' | 'deleted';
  isSelected: boolean;
  isExpanded: boolean;
  cellErrors: Record<string, string>; // Map<columnId: string, errorMessage: string>
  originalData: RowData; // This will only be updated when user click the save button.
  data: RowData;
};

export type DataTableCustomEmptyStateComponentProps<RowData> = Prettify<
  DataTableHooks<RowData> & { emptyReason: RowEmptyReason }
>;

export const DEFAULT_CLIENT_SORT_FN = (): number => 0;

export type DataTableLoader = { show: boolean; text: string };

export type DataTableState<RowData> = {
  // ==================================
  // 1. Basis Table Options
  // ==================================
  tableId: string;
  /**
   * DataTableType that indicates if updates, filtering, searching, sorting, and pagination are done locally or on the server.
   * 'client-side': above actions are performed locally.
   * 'server-side': above actions are performed via API calls to a server.
   *
   * There is no overlap with this functionality.  It is all one or the other.
   *
   * To test out server-side content in StoryBook, see ./src/DataTable/_storybook/mockServer/README.md
   * It may need to be forced to work.
   * */
  tableType: 'client-side' | 'server-side';

  // ==================================
  // 2. UI Options
  // ==================================
  rootClassName: string;
  rootStyle: React.CSSProperties;
  tableStyle: React.CSSProperties;
  tableLayout: 'medium' | 'compact';
  tableShowVerticalDividers: boolean;
  tableStickyHeaders: boolean;
  tableWrapHeaderStyle: 'wrap' | 'nowrap';
  /** boolean that allows for the use of row styling for deleted and voided rows. Default: true. */
  grayDeletedRows: boolean;

  // ==================================
  // 3. Table Title Panel: title, entity name, add row component, csv download button
  // ==================================
  tableTitle: string;

  entityName: {
    showInTableTitle: boolean;
    showInControlPanel: boolean;
    prefix: '' | 'Approximately' | 'More Than';
    singular: string;
    plural: string;
  };

  lastDataUpdateTimeInTableTitle: {
    show: boolean;
    updateTime: Date;
  };

  showServerSideRefreshButton: boolean;

  // The custom component to add new row.
  CustomAddRowComponent?: React.ComponentType<DataTableHooks<RowData>>;

  csvDownload: {
    enableDownloadOptions: boolean;
    showButton: boolean;
    buttonText: string;
    downloadFilePrefix: string;
    fetchCSV?: (args: DataTableFetchRowDataArgs) => Promise<{ blob: Blob }>;
  };

  // ==================================
  // 4. Quick Filter Panel
  // ==================================
  quickFilter: {
    /**
     * - none: don't show the quick filter panel
     * - one-line: show the filter option in a single line
     * - multi-lines: show the filter option in a multiple lines. Need to add the `option.text` as a string array.
     * - multi-select: show the filter option in a multi Select component
     */
    layout: 'none' | 'one-line' | 'multi-lines' | 'multi-select';

    /**
     * Determine whether to show all the options in the quick filter panel
     *
     * - true: show all options
     * - false: show less options (default)
     */
    showAllOptions: boolean;

    /**
     * Determine whether to show the "showAllOptionsButton" in the quick filter panel
     *
     * - true: show the button
     * - false: hide the button (default)
     */
    showAllOptionsButton: boolean;

    /** select option id */
    selectedOptionIds: Array<string>;

    /** quick filter options */
    options: ReadonlyArray<{
      /**
       * A quick filter option should be a unique value
       */
      id: string;

      /**
       * A label displayed in an option
       *
       * To showcase the label in multiple lines, configure the `label` as a string
       * array. Additionally, ensure that the `layout` is set to `multi-lines`.
       * Otherwise, it will concat the string array into a single-line label.
       */
      label: string | ReadonlyArray<string>;

      /**
       * A filter function for the client-side table
       *
       * Given a `rowData` input, return `true` to retain it and `false` to discard it.
       */
      clientSideFilterFn?: (rowData: RowData) => boolean;

      /**
       * A function to generate the query param for the server-side table
       *
       * Return a query string for the backend API
       */
      serverSideQueryParamFn?: () => string;

      /**
       * Determine whether to show the quick filter options based on `showAllOptions` state
       *
       * - true: only shows up when the "showAllOptions" is set to true.
       * - false: it's a required option and shows up all the time.
       */
      isOptional?: boolean;
    }>;
  };

  // ==================================
  // 5. Data Filter Panel
  // ==================================
  dataFilter: {
    show: boolean;
    isHideable: boolean;
    isExpanded: boolean;
    filterIds: string[]; // The order of the data filters.
    filters: Record<
      string, // key: filterId
      DataTableFilter<RowData, unknown> & {
        originalConditionValues: ReadonlyArray<unknown>;
        conditionValues: ReadonlyArray<unknown>; // if conditionValues.length is 0, this column filter won't not be applied.
      }
    >;
  };

  // ==================================
  // 6. Control Panel: bulk edit, edit table button, delete rows checkbox, search box, paginator
  // ==================================
  bulkEdit: {
    layout: 'none' | 'button' | 'menu' | 'row';
    title?: (selectedRowCount: number) => string;
    selectedOptionIndex: number;
    options: ReadonlyArray<{
      name: string;
      clientSideEditRowInPlaceFn?: (rowData: RowData) => void; // This function will be utilized with 'immer'
      serverSideEditRowsAsyncFn?: (selectedRows: RowData[]) => Promise<void>;
    }>;

    confirmModal?: (bulkEditOptionName: string, selectedRowCount: number) => AlertData;
    completeModal?: (bulkEditOptionName: string, selectedRowCount: number) => ModalAlertData;
    completeToast?: (bulkEditOptionName: string, selectedRowCount: number) => ToastAlertData;
  };

  // The custom component to do the bulk edit.
  CustomBulkEditComponent?: React.ComponentType<DataTableHooks<RowData>>;

  editTableButton: {
    show: boolean;
    showSaveAndCancelButtons: boolean;
    serverSideSaveMultipleRowsFn?: (rowData: RowData[]) => Promise<void>;
    onRowsSaved?: (rowData: RowData[]) => void;
    saveAlerts?: DataTableAlertFunctions<RowData>;
    cancelAlerts?: DataTableAlertFunctions<RowData>;
  };

  deletedRowsCheckbox: {
    show: boolean;
    checked: boolean;
  };

  searchBox: {
    show: boolean;
    inputText: string;
  };

  columnConfig: {
    enable: boolean;
    isPopoverOpen: boolean;
    configurableColumnIds: string[];
    defaultOptions: {
      columnId: string;
      columnName: string;
      hideColumn?: boolean; // default: false
    }[];
  };

  pagination: {
    layout: 'none' | PaginatorDisplayMode;
    pageIndex: number;
    pageSize: number; // show 'All Rows' if pageSize <= 0
    pageSizeOptions: ReadonlyArray<number>;
    paginatorLocation: 'top-right' | 'bottom-left' | 'top-and-bottom';
    serverSideTotalCount: number;
  };

  // ==================================
  // 7. Columns
  // ==================================
  showColumnFilters: boolean; // default: true

  /**
   * Decide to show/hide the columns and the display order of the columns.
   */
  columnIds: string[];

  columns: Record<
    string, // key: columnId
    {
      columnId: string;
      dataValidator?: (rowData: RowData) => string;
      determineCellAlert?: (rowData: RowData) => string;
      determineCellTooltip?: (args: DataTableDetermineCellTooltipArgs<RowData>) => DataTableCellTooltipProps;
      isLoading: boolean;
      configOptionName: string;
      hide: boolean;

      // Header
      headerAlignRight: boolean;
      headerTooltip?: TooltipProps['text'];

      // Components
      Header: React.ComponentType<DataTableHooksAndColumnId<RowData>>;
      Footer?: React.ComponentType<DataTableHooksAndColumnId<RowData>>;
      Cell: React.ComponentType<DataTableHooksAndRowId<RowData>>;
      EditCell: React.ComponentType<DataTableHooksAndRowId<RowData>>; // Default: <Cell />
      DeleteCell: React.ComponentType<DataTableHooksAndRowId<RowData>>; // Default: <Cell />

      // Cell Style and Class
      cellStyle?: (row: RowObject<RowData>) => React.CSSProperties | undefined;
      cellClassName?: (row: RowObject<RowData>) => string;

      // Client Side Sorting
      sort: {
        enable: boolean;
        direction: DataTableSortDirection;
        clientSideSortAscFn: (row1: RowData, row2: RowData) => number;
        // accepts extra param: direction
        // this enables handling of nullable values
        clientSideSortFn: (row1: RowData, row2: RowData, direction: DataTableSortDirection) => number;
      };

      // Column Filter
      filter: {
        enable: boolean;
        isOpen: boolean;
        conditionValues: unknown[]; // if conditionValues.length is 0, this column filter won't not be applied.
        clientSideColumnFilterFn?: (args: { rowData: RowData; conditionValues: unknown[] }) => boolean;
        serverSideQueryParamFn?: (args: { conditionValues: unknown[] }) => string;
        ColumnFilterComponent: React.ComponentType<DataTableColumnFilterProps<RowData, unknown>>;
      };

      // Client Side Full Text Search
      clientSideFullTextSearch: {
        enable: boolean; // Row Function columns are not allowed to search
        getTextByRow: (row: RowData) => string;
      };

      // Client Side CSV Download
      clientSideCsvDownload: {
        enable: boolean; // Row Function columns are not allowed to download
        headerText: string;
        getTextByRow: (row: RowData) => string;
      };
    }
  >;

  // ==================================
  // 8. Rows
  // ==================================

  /**
   * Client-side: filtered and sorted but not paginated. The size can change after client-side filtering.
   * Server-side: all the filtered, sorted, and paginated rows return from server.
   */
  filteredAndSortedRowIds: number[];

  rows: Record<
    number, // key: rowId
    RowObject<RowData> // include row meta and row data
  >;

  /** determine whether a row is selectable */
  determineSelectableRow: (rowData: RowData) => boolean;

  /**
   * a callback function that runs on each row as it is rendered – should return an object of props to
   * be added to the row.
   */
  onRenderRowProps?: (row: RowObject<RowData>) => JSX.IntrinsicElements['tr'];

  serverSideFetchRowDataFn: (args: DataTableFetchRowDataArgs) => Promise<{ rowData: RowData[]; totalCount: number }>;
  serverSideFetchRowDataArgs: DataTableFetchRowDataArgs;
  forceFetchRowDataCounter: number; // Force trigger fetching data by increasing the forceFetchRowDataCounter

  // ==================================
  // 9. Expanded Table Row
  // ==================================
  ExpandedTableRow?: React.ComponentType<DataTableHooksAndRowId<RowData>>;

  /**
   * Callback function to determine which rows are expandable.
   */
  determineExpandableRow: (rowData: RowData) => boolean;

  // ==================================
  // 10. Click Rows
  // ==================================
  onRowClickLeft?:
    | ((rowId: number) => DataTableAction<RowData>) // custom row click action
    | 'toggle-select-row'
    | 'toggle-expand-row';

  contextMenu: {
    enable: boolean;
    rowId: number;
    columnId: string;
  };

  // ==================================
  // 11. Empty State
  // ==================================
  CustomEmptyStateComponent?: React.ComponentType<DataTableCustomEmptyStateComponentProps<RowData>>;

  // ==================================
  // 12. Utils: loader, awaitable modal, toast
  // ==================================
  /** Loader shows when `externalLoader.show || loader.show` is true */
  externalLoader: DataTableLoader;
  loader: DataTableLoader;
  awaitableModalCounter: number;
  awaitableModals: ModalProps[];
  toastStack: Omit<ToastProps, 'id'>[];
};
