import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgLightbulbLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M8 14.5L8.98058 14.3039C8.93148 14.0584 8.79207 13.8402 8.58991 13.6925L8 14.5ZM8.5 17L7.51942 17.1961C7.6129 17.6635 8.02332 18 8.5 18V17ZM15.5 17V18C15.9767 18 16.3871 17.6635 16.4806 17.1961L15.5 17ZM16 14.5L15.3794 13.7159C15.1935 13.863 15.0659 14.0714 15.0194 14.3039L16 14.5ZM9 19C8.44772 19 8 19.4477 8 20C8 20.5523 8.44772 21 9 21V19ZM15 21C15.5523 21 16 20.5523 16 20C16 19.4477 15.5523 19 15 19V21ZM10 22C9.44772 22 9 22.4477 9 23C9 23.5523 9.44772 24 10 24V22ZM14 24C14.5523 24 15 23.5523 15 23C15 22.4477 14.5523 22 14 22V24ZM12 0C7.46353 0 4 3.933 4 8.67101H6C6 4.86132 8.73647 2 12 2V0ZM4 8.67101C4 11.662 5.38802 13.8302 7.41009 15.3075L8.58991 13.6925C7.01198 12.5397 6 10.9402 6 8.67101H4ZM8.5 18H15.5V16H8.5V18ZM16.6206 15.2841C18.6018 13.7161 20 11.5646 20 8.67101H18C18 10.8183 16.9982 12.4346 15.3794 13.7159L16.6206 15.2841ZM20 8.67101C20 3.933 16.5365 0 12 0V2C15.2635 2 18 4.86132 18 8.67101H20ZM7.01942 14.6961L7.51942 17.1961L9.48058 16.8039L8.98058 14.3039L7.01942 14.6961ZM15.0194 14.3039L14.5194 16.8039L16.4806 17.1961L16.9806 14.6961L15.0194 14.3039ZM9 21H15V19H9V21ZM10 24H14V22H10V24Z"
      fill={props.color}
    />
  </svg>
);
SvgLightbulbLarge.displayName = 'SvgLightbulbLarge';
const LightbulbLarge = withForgeIconProps(SvgLightbulbLarge, {
  name: 'Lightbulb',
  usage: 'Use to indicate a tip, best practice, idea, or recommendation',
  related: 'light, tip, idea',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'large',
});
export default LightbulbLarge;
