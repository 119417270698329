import { ReactElement } from 'react';
import SelectedOption, { SelectedOptionProps } from './SelectedOption';
import { Virtuoso } from 'react-virtuoso';
import { HighVolumeMultiSelectOption } from '../HighVolumeMultiSelect';

interface SelectedOptionsProps extends Omit<SelectedOptionProps, 'label' | 'disabled' | 'value'> {
  selectedOptions: HighVolumeMultiSelectOption[];
}

const SelectedOptions = ({
  selectedOptions,
  handleTagRemoval,
  keyDownToggleOption,
}: SelectedOptionsProps): ReactElement => {
  return (
    <Virtuoso
      data={selectedOptions}
      itemContent={(idx, { label, disabled, value }: HighVolumeMultiSelectOption) => (
        <SelectedOption
          value={value}
          handleTagRemoval={handleTagRemoval}
          label={label}
          disabled={disabled}
          keyDownToggleOption={keyDownToggleOption}
        />
      )}
    />
  );
};

export default SelectedOptions;
