import { ReactElement, useContext } from 'react';
import { GroupBase, MenuListProps } from 'react-select';
import Button from '../../Button';
import { FormFieldLayoutContext } from '../../FormFieldLayout/FormFieldLayoutContext';
import { StructuredOption, classes } from '../Select';
import { useSelectContext } from '../context/SelectContext';

const CustomMenuList = <IsMulti extends boolean>(
  props: MenuListProps<StructuredOption, IsMulti, GroupBase<StructuredOption>>
): ReactElement => {
  const { children, className, isMulti, innerRef, selectProps } = props;
  const { enableSelectAll, handleSelectAll } = useSelectContext();
  const listId = `select-list-${selectProps.instanceId}`;
  const layoutContext = useContext(FormFieldLayoutContext);
  const selectAllHandler = (): void => {
    handleSelectAll(selectProps.options);
  };
  return (
    <div
      {...classes({
        element: 'menu-list',
        modifiers: { [layoutContext?.layout || '']: true, 'is-multi': isMulti },
        extra: className,
      })}
      ref={innerRef}
      aria-expanded="true"
      role="listbox"
      id={listId}
    >
      {enableSelectAll && isMulti && (
        <div {...classes({ element: 'select-all' })}>
          <Button {...classes({ element: 'select-all-button' })} variant="tertiary" onClick={selectAllHandler}>
            Select All
          </Button>
        </div>
      )}
      {children}
    </div>
  );
};

export default CustomMenuList;
