import { ReactElement } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { forgeClassHelper } from '../../utils/classes';
import { HighVolumeMultiSelectOption } from '../HighVolumeMultiSelect';
import { EmptyPlaceholderComponent } from './EmptyPlaceholderComponent';
import Option, { OptionProps } from './Option';

const classes = forgeClassHelper('high-volume-multiselect');

interface OptionsProps extends Omit<OptionProps, 'label' | 'disabled' | 'value'> {
  memoizedOptions: HighVolumeMultiSelectOption[];
}

const Options = ({
  memoizedOptions,
  id,
  keyDownToggleOption,
  selectedMap,
  toggleOption,
}: OptionsProps): ReactElement => {
  return (
    <Virtuoso
      {...classes('options-container')}
      data={memoizedOptions}
      itemContent={(idx, { value, label, disabled }: HighVolumeMultiSelectOption) => (
        <Option
          value={value}
          id={id}
          label={label}
          disabled={disabled}
          selectedMap={selectedMap}
          toggleOption={toggleOption}
          keyDownToggleOption={keyDownToggleOption}
        />
      )}
      components={{
        EmptyPlaceholder: !memoizedOptions.length ? EmptyPlaceholderComponent : undefined,
      }}
    />
  );
};

export default Options;
