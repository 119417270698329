import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgLightbulbSmall = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={12}
    height={16}
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M3.14286 9.50531L4.12152 9.29982C4.06561 9.03355 3.90361 8.80155 3.67289 8.65734L3.14286 9.50531ZM3.66667 12L2.68801 12.2055C2.78522 12.6685 3.19358 13 3.66667 13V12ZM8.33333 12V13C8.80642 13 9.21478 12.6685 9.31199 12.2055L8.33333 12ZM8.85714 9.50531L8.29645 8.67729C8.08166 8.82274 7.93179 9.04596 7.87848 9.29982L8.85714 9.50531ZM4 14C3.44772 14 3 14.4477 3 15C3 15.5523 3.44772 16 4 16V14ZM8 16C8.55228 16 9 15.5523 9 15C9 14.4477 8.55228 14 8 14V16ZM6 0C2.72368 0 0 2.46409 0 5.68797H2C2 3.68811 3.70489 2 6 2V0ZM0 5.68797C0 7.66295 1.05471 9.37937 2.61282 10.3533L3.67289 8.65734C2.65958 8.02395 2 6.92759 2 5.68797H0ZM3.66667 13H8.33333V11H3.66667V13ZM9.41784 10.3333C10.9351 9.30594 12 7.60909 12 5.68797H10C10 6.84751 9.35064 7.96344 8.29645 8.67729L9.41784 10.3333ZM12 5.68797C12 2.46409 9.27632 0 6 0V2C8.29511 2 10 3.68811 10 5.68797H12ZM2.1642 9.7108L2.68801 12.2055L4.64533 11.7945L4.12152 9.29982L2.1642 9.7108ZM7.87848 9.29982L7.35467 11.7945L9.31199 12.2055L9.8358 9.7108L7.87848 9.29982ZM4 16H8V14H4V16Z"
      fill={props.color}
    />
  </svg>
);
SvgLightbulbSmall.displayName = 'SvgLightbulbSmall';
const LightbulbSmall = withForgeIconProps(SvgLightbulbSmall, {
  name: 'Lightbulb',
  usage: 'Use to indicate a tip, best practice, idea, or recommendation',
  related: 'light, tip, idea',
  isLabelRequired: false,
  isNeutralInteractive: false,
  size: 'small',
});
export default LightbulbSmall;
