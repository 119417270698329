import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPalmLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M5 5.5a1 1 0 0 0-2 0zm15 4.56.995.1a1 1 0 0 0-.255-.773zM17 3a1 1 0 1 0-2 0zm-8-.5a1 1 0 0 0-2 0zM13 1a1 1 0 1 0-2 0zm2.333 12.723-.136.99a1 1 0 0 0 1.12-.81zm3.931 3.172.957.289zm.094-.489-.995-.1zM3 5.5v10.474h2V5.5zm.314 12.17c1.9 4.7 5.518 6.653 9.069 6.287 3.474-.357 6.677-2.922 7.839-6.773l-1.915-.578c-.94 3.115-3.494 5.09-6.129 5.362-2.557.263-5.4-1.068-7.01-5.048zm17.04-1.163.64-6.347-1.989-.201-.642 6.346zM17 10.06V3h-2v7.06zM7 2.5v8.292h2V2.5zM11 1v9.792h2V1zm4.016 8.88-.666 3.664 1.967.358.667-3.663zm.453 2.852c-1.206-.165-2.786.317-4.035 1.174C10.16 14.781 9 16.211 9 18.12h2c0-1.023.618-1.913 1.566-2.564.974-.669 2.06-.92 2.631-.841zm5.27-3.345c-.799-.88-2.742-1.566-5.22-.204l.963 1.753c1.788-.983 2.69-.302 2.778-.203zm-.518 7.797c.07-.232.11-.462.132-.677l-1.99-.202a1.6 1.6 0 0 1-.056.301zM3 15.974c0 .554.089 1.139.314 1.695l1.854-.75A2.5 2.5 0 0 1 5 15.975z"
      fill={props.color}
    />
  </svg>
);
SvgPalmLarge.displayName = 'SvgPalmLarge';
const PalmLarge = withForgeIconProps(SvgPalmLarge, {
  name: 'Palm',
  usage: 'Use to indicate the need to pause or wait before an action',
  related: 'pause, hand, wait',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'large',
});
export default PalmLarge;
