import { ReactElement } from 'react';
import Tag from '../../Tag';
import { forgeClassHelper } from '../../utils/classes';

const classes = forgeClassHelper('high-volume-multiselect');

export interface SelectedOptionProps {
  value: string;
  handleTagRemoval: (arg: string) => (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  label: string;
  disabled: boolean | undefined;
  keyDownToggleOption: (arg: string) => React.KeyboardEventHandler<HTMLDivElement>;
}
const SelectedOption = ({
  value,
  handleTagRemoval,
  label,
  disabled,
  keyDownToggleOption,
}: SelectedOptionProps): ReactElement => {
  return (
    <div {...classes('selected-option-container')}>
      <Tag
        key={value}
        onRemove={handleTagRemoval(value)}
        size="medium"
        text={label}
        isRemovable={!disabled}
        tabIndex={disabled ? -1 : 0}
        onKeyDown={keyDownToggleOption(value)}
      />
    </div>
  );
};

export default SelectedOption;
