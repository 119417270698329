import * as React from 'react';
import { SVGProps } from 'react';
import { withForgeIconProps } from './ForgeIconProps';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgStopLarge = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): JSX.Element => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="fi_c_icon"
    role="img"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_2_102)">
      <path
        d="M7.00002 7L12 12M12 12L17 17M12 12L17 7M12 12L7 17M6.92308 23H17.0769L23 17.0769V6.92308L17.0769 1H6.92308L1 6.92308V17.0769L6.92308 23Z"
        stroke={props.color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2_102">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
SvgStopLarge.displayName = 'SvgStopLarge';
const StopLarge = withForgeIconProps(SvgStopLarge, {
  name: 'Stop',
  usage: 'Use to indicate the need to stop or discontinue an action',
  related: 'stop, discontinue',
  isLabelRequired: true,
  isNeutralInteractive: false,
  size: 'large',
});
export default StopLarge;
