import { ReactElement, WeakValidationMap } from 'react';
import { DataTableRef } from './DataTableRef';
import type {
  DataTableHooks,
  DataTableState,
  DataTableCustomEmptyStateComponentProps,
  RowObject,
} from './store/stateTypes';
import type {
  DataTableColumn,
  DataTableHooksAndRowId,
  DataTableClientSideFilterFn,
  DataTableServerSideQueryParamFn,
  DataTableFilterComponentProps,
  DataTableSingleEditConfirmModalFn,
  DataTableSingleEditCompleteModalFn,
  DataTableSingleEditCompleteToastFn,
  DataTableDataValidator,
  DataTableClientSideSortAscFn,
  DataTableColumnFilterProps,
  DataTableGetTextByRow,
  DataTableHooksAndColumnId,
  DataTableFilterDescribeConditionValue,
  DataTableDetermineCellAlert,
  DataTableDefaultPropValues,
  DataTableClientSideSortFn,
  DataTableDetermineCellTooltip,
} from './utils/internalTypes';
import { AddNull, Prettify } from '../utils/types';
import PropTypes from 'prop-types';
import {
  optionalBoolValidator,
  optionalFunctionValidator,
  optionalStringValidator,
  requiredFunctionValidator,
} from '../utils/betterPropTypes';

// ====================================
// 1. DataTable Props
// ====================================
export type DataTableProps<RowData> = {
  /**
   * Determines how the DataTable interacts with the "Row Data"
   *
   * - `client-side`: the rowData is already loaded into the memory or browser before given to DataTable.
   *                  Any changes to the row data, like bulk editing, filtering, or pagination, happen
   *                  in memory only.
   * - `server-side`: the rowData comes entirely from the backend server. Any changes to the data will
   *                  require an API call to the server. For example, in server-side mode, actions like
   *                  bulk editing, filtering, and pagination will make an API call to get/update the
   *                  proper data from server.
   *                  https://athenaconfluence.athenahealth.com/x/VRmDI
   */
  tableType: DataTableType;

  // ==================================
  // 1. UI Options (8)
  // ==================================
  /** Classes forwarded to outermost <div> */
  className?: string;
  /** Style tags added to outermost <div> */
  rootStyle?: React.CSSProperties;
  /** Style tags added to <table> element */
  tableStyle?: React.CSSProperties;
  /** Affects spacing between elements.  Either "medium" | "compact" */
  tableLayout?: DataTableLayout;
  /** Determines whether columns should have dividers between them */
  tableShowVerticalDividers?: boolean;
  /** When true, the header row and footer row are "sticky" and won't scroll with the data */
  tableStickyHeaders?: boolean;
  /** Affects how the Table header behaves in a constrained viewport.  Either "wrap" | "nowrap" */
  tableWrapHeaderStyle?: DataTableWrapHeaderStyle;
  /** if set to false, display deleted rows without a background color. */
  grayDeletedRows?: boolean;

  /**
   * a callback function that runs on each row as it is rendered – should return
   * an object of props to be added to the row.
   */
  onRenderRowProps?: (row: RowObject<RowData>) => JSX.IntrinsicElements['tr'];

  // ==================================
  // 2. Table Title Panel
  // ==================================
  /** The title for the table */
  title?: string;
  /** Defines what each row of the Table Represents
   *  - __showInTableTitle__ (required): `boolean` that indicates if the entity name should be shown in the datatable title
   *  - __prefix__ (required): `string` prefix for the entity name.  Can be one of `'' | 'Approximately' | 'More Than'`
   *  - __singular__ (required): `string` that holds the singular display string of the entity name
   *  - __plural__ (required): `string` that holds the plural display string of the entity name
   */
  entityName?: DataTableEntityName;
  /** Displays when the data was last updated
   *  - __show__ (required): `boolean` that indicates if this information is displayed
   *  - __updateTime__ (required): `Date` that contains that last update time of the datatable data
   */
  lastDataUpdateTimeInTableTitle?: DataTableLastDataUpdateTimeInTableTitle;
  /**
   * Refresh button: `boolean` to show or not show icon
   */
  showServerSideRefreshButton?: boolean;
  /** Custom component rendered below the table title.
   *
   * Its intended use is to add new rows to the table.
   */
  CustomAddRowComponent?: React.ComponentType<DataTableHooks<RowData>>;

  /**
   * Allows downloading the table data as a CSV
   *  - __enableDownloadOptions__: `boolean` enable CSV download menu when filters are applied. Default: `true`
   *  - __showButton__ (required): `boolean` indicating if the download CSV button should be accessible
   *  - __buttonText__: `string` holding a custom display string for the download button. Default: `'Download CSV'`
   *  - __downloadFilePrefix__: `string` holding a prefix for the CVS file name. Default: `'data-table'`
   *  - __fetchCSV__: `function` of type `DataTableFetchCSVFn` that retrieves the CSV file from the server
   *    - fetchCSV arguments:
   *      - __pageIndex__: `number`
   *      - __pageSize__: `number`
   *      - __searchText__: `string`
   *      - __sortByColumnId__: `string`
   *      - __sortOrder__: `'' | 'asc' | 'desc'`
   *      - __includeDeletedRows__: `boolean`
   *      - __quickFilterQueryParams__: `string[]`
   *      - __dataFilterQueryParams__: `string[]`
   *      - __columnFilterQueryParams__: `string[]`
   *
   *    returns `Promise<{ blob: Blob }>`
   */
  csvDownload?: DataTableCsvDownload;

  // ==================================
  // 3. Quick Filter Panel
  // ==================================
  /**
   * Configures a panel used to quickly filter the data using a list
   * of application-defined filters.
   *
   *  - __layout__: DataTableQuickFilterLayout
   *    - `none`: don't show the quick filter panel
   *    - `one-line`: show the filter option in a single line
   *    - `multi-lines`: show the filter option in a multiple lines. Need to add the `option.text` as a string array.
   *    - `multiselect`: show the filter option in a Multiselect component
   *
   *
   * Determine whether to show all the options in the quick filter panel
   *  - __showAllOptions__: `boolean`
   *    - `true`: show all options
   *    - `false`: show less options (default)
   *
   *
   * Determine whether to show the "showAllOptionsButton" in the quick filter panel
   *  - __showAllOptionsButton__: boolean
   *    - `true`: show the button
   *    - `false`: hide the button (default)
   *
   * Select option id
   *  - __selectedOptionIds__: `string[]`
   *
   * Quick filter options
   *  - __options__: Readonly `Array` that contains:
   *
   *    A quick filter option should be a unique value
   *      - __id__ (required): `string`
   *
   *    A label displayed in an option
   *    To showcase the label in multiple lines, configure the `label` as a string
   *    array. Additionally, ensure that the `layout` is set to `multi-lines`.
   *    Otherwise, it will concat the string array into a single-line label.
   *      - __label__ (required): `string | Readonly string[]`
   *
   *    A filter function for the client-side table
   *    Given a `rowData` input, return `true` to retain it and `false` to discard it.
   *      - __clientSideFilterFn__: `DataTableClientSideQuickFilterFn<RowData>`
   *
   *    A function to generate the query param for the server-side table
   *    Return a query `string` for the backend API
   *      - __serverSideQueryParamFn__: `DataTableServerSideQuickFilterQueryParamFn`
   *
   *    Determine whether to show the quick filter options based on `showAllOptions` state
   *      - __isOptional__: `boolean`
   *        - `true`: only shows up when the `showAllOptions` is set to true.
   *        - `false`: it's a required option and shows up all the time.
   */
  quickFilter?: DataTableQuickFilter<RowData>;

  // ==================================
  // 4. Data Filter Panel
  // ==================================
  /** When set to false, prevents the filter panel from collapsing on click */
  filterHideable?: boolean;
  /** When set to false, filter panel collapsed */
  filterPanelExpanded?: boolean;
  /** Configures filters for the data
   *
   * Use `DT.Filter.create*` to create data filter instances.
   * - `DT.Filter.createDateInputFilter`
   * - `DT.Filter.createDateRangeInputFilter`
   * - `DT.Filter.createNumericInputFilter`
   * - `DT.Filter.createNumericRangeInputFilter`
   * - `DT.Filter.createRadioGroupFilter`
   * - `DT.Filter.createSelectFilter`
   * - `DT.Filter.createTextInputFilter`
   *
   * Optional array of filters.  Each array element contains:
   *  - __filterId__ (required): `string` that identifies a filter
   *  - __filterLabel__ (required): `string` for labeling this filter in the filter panel
   *  - __clientSideFilterFn__: `function` of `DataTableClientSideFilterFn<RowData, FilterConditionValue>` type that returns `boolean`.  If `true`, this row passes the filter test and is displayed in the datatable.  Client-side only
   *  - __serverSideQueryParamFn__: `function` of `DataTableServerSideQueryParamFn<FilterConditionValue>` type that constructs a `string` of filter values to be passed to the server-side filter function
   *  - __describeConditionValue__ (required): `function` of `DataTableFilterDescribeConditionValue<FilterConditionValue>` type that returns a `string` describing this filter selection.  Used in filter tags
   *  - __DataFilterComponent__ (required): `function` of `React.ComponentType<DataTableFilterComponentProps<RowData, FilterConditionValue>>` type that returns the JSX for this filter control
   */
  filters?: DataTableFilters<RowData>;

  // ==================================
  // 5. Control Panel: bulk edit, edit table button, delete rows checkbox, search box, paginator
  // ==================================
  /**
   * Allows editing data in bulk
   *
   *  - __layout__ (required): `string`. One of `"none" | "button" | "menu" | "row"`
   *  - __title__: `function` of type `DataTableBulkEditTitleFn` that returns a display `string` based on the number of rows selected
   *  - __options__ (required): `object[]` of bulk actions
   *    - __name__ (required): `string` describing a bulk action
   *    - __clientSideEditRowInPlaceFn__: `function` of type `DataTableClientSideEditRowInPlaceFn<RowData>` that performs an edit action on one row
   *    - __serverSideEditRowsAsyncFn__: `function` of type `DataTableServerSideEditRowsAsyncFn<RowData>` that makes a remote API call to the server to edit all selected rows
   *    - __confirmModal__: `function` of type `DataTableBulkEditConfirmModalFn` that returns an `object containing a title string and message body (`string` or `JSX.Element`) for a confirmation `Modal`
   *    - __completeModal__: `function` of type `DataTableBulkEditCompleteModalFn` that returns an `object` containing a title `string` and message body (`string` or `JSX.Element`) for a results `Modal`.  Also includes `alertType` of `undefined | 'attention' | 'info' | 'success' | 'critical'`
   *    - __completeToast__: `function` of type `DataTableBulkEditCompleteToastFn` that returns an `object` containing a title `string` and message body (`string` or `JSX.Element`) for a results `Toast`.  Also includes `alertType`: `'attention' | 'info' | 'success'`
   */
  bulkEdit?: DataTableBulkEdit<RowData>;
  /** Custom component used to perform bulk edits */
  CustomBulkEditComponent?: React.ComponentType<DataTableHooks<RowData>>;
  /**
   * Adds a button above the table header allowing edits within the table view
   *
   *  - __show__ (required): `boolean` that indicates in the edit table button can be shown.  If true, there must be support for table editing
   *  - __serverSideSaveMultipleRowsFn__: `function` of type `DataTableServerSideSaveMultipleRowsFn<RowData>` that will make an API call to edit the table with an array of the passed modified RowData.  Server-side only
   *  - __onRowsSaved__: callback of type `DataTableOnRowsSaved<RowData>` that is called after a table is saved
   *  - __saveAlerts__: `object` of type `DataTableAlertFunctions<RowData>` used during table saved that contains:
   *    - __confirmModal__: `function` of type `DataTableSingleEditConfirmModalFn<RowData>` that returns an `object` containing a title `string` and message body (`string` or `JSX.Element`) for a confirmation `Modal`
   *    - __completeModal__: `function` of type `DataTableSingleEditCompleteModalFn<RowData>` that returns an `object` containing a title `string` and message body (`string` or `JSX.Element`) for a results `Modal`.  Also includes `alertType` of `undefined | 'attention' | 'info' | 'success' | 'critical'`
   *    - __completeToast__: `function` of type `DataTableSingleEditCompleteToastFn<RowData>` that returns an `object` containing a title `string` and message body (`string` or `JSX.Element`) for a results `Toast`.  Also includes `alertType`: `'attention' | 'info' | 'success'`
   *  - __cancelAlerts__: `object` of type `DataTableAlertFunctions<RowData>` used when table edit are canceled and contains:
   *    - __confirmModal__: `function` of type `DataTableSingleEditConfirmModalFn<RowData>` that returns an `object` containing a title `string` and message body (`string` or `JSX.Element`) for a confirmation `Modal`
   *    - __completeModal__: `function` of type `DataTableSingleEditCompleteModalFn<RowData>` that returns an `object` containing a title `string` and message body (`string` or `JSX.Element`) for a results `Modal`.  Also includes `alertType` of `undefined | 'attention' | 'info' | 'success' | 'critical'`
   *    - __completeToast__: `function` of type `DataTableSingleEditCompleteToastFn<RowData>` that returns an `object` containing a title `string` and message body (`string` or `JSX.Element`) for a results `Toast`.  Also includes `alertType`: `'attention' | 'info' | 'success'`
   */
  editTableButton?: DataTableEditTableButton<RowData>;
  /** Enables a checkbox allowing deleted data to be seen */
  showDeleteRowCheckbox?: boolean;
  /** Enables a search box */
  showSearchBox?: boolean;

  /** Enables a column config */
  showColumnConfigButton?: boolean;

  /**
   * Configures pagination
   *  - __layout__ (required): `string` that is one of `"none" | "default" | "compact" | "withPageJump"`
   *  - __pageIndex__ (required): `number` with the current page
   *  - __pageSize__ (required): `number` with the maximum number of rows on a page
   *  - __pageSizeOptions__ (required): `readonly arrayOf number` that contains a list of supported page sizes for this datatable
   *  - __paginatorLocation__ (required): `string` that is one of `'top-right' | 'bottom-left' | 'top-and-bottom'`
   */
  pagination?: DataTablePagination<RowData>;

  // ==================================
  // 6. Columns
  // ==================================
  /** Adds filters in column headers */
  showColumnFilters?: boolean;
  /** Defines the columns for the table and how they render using rowData
   *
   *  - __columnId__ (required): `string` to uniquely identify the column
   *  - __headerTooltip__: `React.ReactNode` that is used as a tooltip for the column header
   *  - __headerAlignRight__: `boolean` that aligns the header text to the right
   *  - __Header__ (required): `(props: DataTableHooksAndColumnId<RowData>) => JSX.Element` that returns the title to the column
   *  - __Footer__: `(props: DataTableHooksAndColumnId<RowData>) => JSX.Element` that returns the title to the column
   *  - __cellStyle__: `(row: RowObject<RowData>) => React.CSSProperties` that returns the style of the cell
   *  - __cellClassName__: `(row: RowObject<RowData>) => string` that returns the className of the cell
   *  - __Cell__ (required): `(props: DataTableHooksAndColumnId<RowData>) => JSX.Element` that returns the display value of a row in the column
   *  - __EditCell__: `(props: DataTableHooksAndColumnId<RowData>) => JSX.Element` that returns the control to edit this cell
   *  - __DeleteCell__: `(props: DataTableHooksAndColumnId<RowData>) => JSX.Element` that returns the display value of a deleted row in the column
   *  - __determineCellAlert__: `(row: RowData) => string` that returns a non-empty `string` describing cell data validation for this column
   *  - __determineCellTooltip__: `({ row: RowObject<RowData>, columnId: string, columns: DataTableState<RowData>['columns'] }) => DataTableCellTooltipProps` that returns a TooltipProp object to render a cell tooltip
   *  - __dataValidator__: `(row: RowData) => string` of type `DataTableDataValidator<RowData>` that returns a non-empty `string` describing cell data validation for this column
   *  - __clientSideSortAscFn__ (deprecated): `(row1: RowData, row2: RowData) => number` for ascending sort of this column that returns `int` (less: -1, equal: 0, greater: 1).  Client-side only
   *  - __clientSideSortFn__: `(row1: RowData, row2: RowData, direction: DataTableSortDirection) => number` for ascending sort of this column that returns `int` (less: -1, equal: 0, greater: 1).  Client-side only
   *  - __columnFilter__: `object` for filtering by the values in this column
   *    - __clientSideColumnFilterFn__: `(args: { rowData: RowData; conditionValues: FilterConditionValue[] }) => boolean` for client-side filtering.  Returns `true` if the column cell passes
   *    - __serverSideQueryParamFn__: `(args: { conditionValues: FilterConditionValue[] }) => string` for creating URL parameters for a server-side API filtering call.  Returns a `string`
   *    - __ColumnFilterComponent__ (required): `(props: DataTableColumnFilterProps<RowData, FilterConditionValue>) => JSX.Element` to input filter values for this column
   *  - __configOptionName__: `string` to make the column configurable in the column config menu
   *  - __defaultHideColumn__: `boolean` set true to hide this column by default
   *  - __clientSideFullTextSearch__: `object` for client-side text searching in this column
   *    - __enable__ (required): `boolean` indicating if searching is enabled
   *    - __getTextByRow__ (required): `(row: RowData) => string` that returns a `string` representation for the current cell.  Allows for this column to be searched for matching text
   *  - __clientSideCsvDownload__: `object` for client-side CSV generation
   *    - __enable__ (required): `boolean` that indicates if this column's cell data can be exported to a CSV file
   *    - __headerText__ (required): `string` to display in the header for this CSV column.  Should be the same as the display `string` in __Header__
   *    - __getTextByRow__ (required): `(row: RowData) => string` that returns a `string` representing the cell data for this column
   *
   * use DT.Column.create* to create column instances
   */
  columns: ReadonlyArray<Prettify<DataTableColumn<RowData>>>;

  // ==================================
  // 7. Rows
  // ==================================
  /** The data used to render the table.
   *
   * This is an array of the user defined object of key/value pairs for the datatable.
   * The key corresponds to the column name and the value corresponds to the column's cell data type.
   *
   * Each datum is expected to be an object. The only key that DataTable uses
   * directly is `deleted?: boolean`, which marks the row as deleted.
   *
   * Server-side usually sets rowData as [] initially
   */
  rowData: ReadonlyArray<RowData>;
  /** Function used to determine if a row can be selected for bulk edit */
  determineSelectableRow?: (rowData: RowData) => boolean;
  /** Function used to retrieve row data when rendering server-side */
  serverSideFetchRowDataFn?: DataTableServerSideFetchRowDataFn<RowData>;

  // ==================================
  // 8. Expanded Table Row
  // ==================================
  /** Custom component used to render the contents of the row in an expanded view
   *
   * In order to work, the first column must be defined as DT.Column.createRowExpandArrowColumn()
   */
  ExpandedTableRow?: React.ComponentType<DataTableHooksAndRowId<RowData>>;
  /**
   * Callback function to determine which rows are selectable. By default, all rows are expandable,
   * given if ExpandedTableRow prop is being used
   */
  determineExpandableRow?: (rowData: RowData) => boolean;

  // ==================================
  // 9. Click Rows
  // ==================================
  /** What action to take when left-clicking on the row.
   *
   * Pre-configured behaviors are defined under `toggle-select-row` or
   * `toggle-expand-row`, or further customization can be achieved by providing
   * a callback function.
   */
  onRowClickLeft?: DataTableOnRowClickLeft<RowData>;
  /** Enables a context menu that appears when right-clicking on any row. */
  enableContextMenu?: boolean;

  // ==================================
  // 10. Empty State
  // ==================================
  /** What to render if there is no data to show
   *
   * Defaults to using a EmptyState component with generic text
   */
  CustomEmptyStateComponent?: React.ComponentType<DataTableCustomEmptyStateComponentProps<RowData>>;

  // ==================================
  // 11. Loader
  // ==================================
  /**
   * Force show the loader for DataTable
   * - __show__ (required): `boolean` that indicates if the loader is visible
   * - __text__ (required): `string` to display in the loader
   */
  loader?: { show: boolean; text: string };

  // ==================================
  // 12. Callbacks
  // ==================================
  /** onStateChanged will be invoked when the state of the DataTable changes */
  onStateChanged?: (currentState: DataTableState<RowData>, previousState: DataTableState<RowData>) => void;

  /** onSelectedRowsChanged will be invoked when the selected rows change */
  onSelectedRowsChanged?: (currentSelectedRows: RowData[], previousSelectedRows: RowData[]) => void;

  // ==================================
  // 13. Ref
  // ==================================
  /**
   * Forwarded ref for using the imperative DataTable API
   * - __getState__: `() => DataTableState<RowData>`: get the current state of the DataTable
   * - __dispatch__: `(action: DataTableAction<RowData>) => void`: dispatch an action to modify the DataTable state
   * - __dispatchAsyncAction__: `(asyncAction: DataTableAsyncAction<IState>) => Promise<void>`: dispatch an async action to modify the DataTable state
   */
  ref?: React.ForwardedRef<DataTableRef<RowData>>;
};

// ====================================
// 2. DataTable Default Prop Values
// ====================================
// Internal Dev Note: it's better to put 'dataTableDefaultPropValues' in a separate file to modularize the code.
// However, it must be put in this file because forge-guide cannot read the values from different files.
const dataTableDefaultPropValues: DataTableDefaultPropValues<unknown> = {
  tableType: 'client-side',
  className: '',
  rootStyle: {},
  tableStyle: {},
  tableLayout: 'medium',
  tableShowVerticalDividers: false,
  tableStickyHeaders: false,
  tableWrapHeaderStyle: 'wrap',
  grayDeletedRows: true,
  onRenderRowProps: undefined,
  title: '',
  entityName: {
    showInTableTitle: false,
    showInControlPanel: false,
    prefix: '',
    singular: 'entity',
    plural: 'entities',
  },
  lastDataUpdateTimeInTableTitle: {
    show: false,
    updateTime: new Date(),
  },
  showServerSideRefreshButton: false,
  CustomAddRowComponent: undefined,
  csvDownload: {
    enableDownloadOptions: true,
    showButton: false,
    buttonText: 'Download CSV',
    downloadFilePrefix: 'data-table',
    fetchCSV: undefined,
  },
  quickFilter: {
    layout: 'none',
    showAllOptions: false,
    showAllOptionsButton: false,
    options: [],
  },
  filterHideable: true,
  filterPanelExpanded: true,
  filters: [],
  bulkEdit: {
    layout: 'none',
    title: undefined,
    options: [],
    confirmModal: undefined,
    completeModal: undefined,
    completeToast: undefined,
  },
  CustomBulkEditComponent: undefined,
  editTableButton: {
    show: false,
    serverSideSaveMultipleRowsFn: undefined,
    onRowsSaved: undefined,
    saveAlerts: undefined,
    cancelAlerts: undefined,
  },
  showDeleteRowCheckbox: false,
  showSearchBox: false,
  showColumnConfigButton: false,
  pagination: {
    layout: 'none',
    pageIndex: 0,
    pageSize: -1, // show 'All Rows' if pageSize <= 0
    pageSizeOptions: [],
    paginatorLocation: 'top-right',
  },
  showColumnFilters: true,
  columns: [], // required
  rowData: [], // required
  determineSelectableRow: () => true,
  serverSideFetchRowDataFn: async () => ({ rowData: [], totalCount: 0 }),
  ExpandedTableRow: undefined,
  determineExpandableRow: () => true,
  onRowClickLeft: undefined,
  enableContextMenu: false,
  CustomEmptyStateComponent: undefined,
  loader: { show: false, text: '' },
  onStateChanged: undefined,
  onSelectedRowsChanged: undefined,
  ref: undefined,
};
export function getDataTableDefaultPropValues<RowData>(): Readonly<DataTableDefaultPropValues<RowData>> {
  return dataTableDefaultPropValues as DataTableDefaultPropValues<RowData>;
}

// ====================================
// 3. DataTable Component
// ====================================
/** A component used for generating the props table for DataTable in the guide
 *
 * This is *NOT* the implementation of DataTable that should be used.
 * The simplest implementation of DataTable instead follows a format similar to:
 *
 * ```typescript
 * const DT = createDataTable<MyData>({ tableId: 'my-table' });
 * function MyTable() {
 *   return <DT.DataTable {...dataTableProps} />
 * }
 * ```
 *
 * While this component could be adapted to do the above, implementation details
 * within createDataTable would have to be replicated by the application developer.
 * This would include the application creating their own TableStore in order to
 * supply it to ContextMenu, and importing every create* function for filters,
 * columns, etc.
 */
function DataTable<RowData>({
  // tableType, // required, no default value
  className = dataTableDefaultPropValues.className,
  rootStyle = dataTableDefaultPropValues.rootStyle,
  tableStyle = dataTableDefaultPropValues.tableStyle,
  tableLayout = dataTableDefaultPropValues.tableLayout,
  tableShowVerticalDividers = dataTableDefaultPropValues.tableShowVerticalDividers,
  tableStickyHeaders = dataTableDefaultPropValues.tableStickyHeaders,
  tableWrapHeaderStyle = dataTableDefaultPropValues.tableWrapHeaderStyle,
  grayDeletedRows = dataTableDefaultPropValues.grayDeletedRows,
  onRenderRowProps = dataTableDefaultPropValues.onRenderRowProps,
  title = dataTableDefaultPropValues.title,
  entityName = dataTableDefaultPropValues.entityName,
  lastDataUpdateTimeInTableTitle = dataTableDefaultPropValues.lastDataUpdateTimeInTableTitle,
  showServerSideRefreshButton = dataTableDefaultPropValues.showServerSideRefreshButton,
  CustomAddRowComponent = dataTableDefaultPropValues.CustomAddRowComponent as DataTableProps<RowData>['CustomAddRowComponent'],
  csvDownload = dataTableDefaultPropValues.csvDownload,
  quickFilter = dataTableDefaultPropValues.quickFilter,
  filterHideable = dataTableDefaultPropValues.filterHideable,
  filterPanelExpanded = dataTableDefaultPropValues.filterPanelExpanded,
  filters = dataTableDefaultPropValues.filters as DataTableProps<RowData>['filters'],
  bulkEdit = dataTableDefaultPropValues.bulkEdit,
  CustomBulkEditComponent = dataTableDefaultPropValues.CustomBulkEditComponent as DataTableProps<RowData>['CustomBulkEditComponent'],
  editTableButton = dataTableDefaultPropValues.editTableButton,
  showDeleteRowCheckbox = dataTableDefaultPropValues.showDeleteRowCheckbox,
  showSearchBox = dataTableDefaultPropValues.showSearchBox,
  showColumnConfigButton = dataTableDefaultPropValues.showColumnConfigButton,
  pagination = dataTableDefaultPropValues.pagination,
  showColumnFilters = dataTableDefaultPropValues.showColumnFilters,
  // columns, // required, no default value
  // rowData, // required, no default value
  determineSelectableRow = dataTableDefaultPropValues.determineSelectableRow,
  serverSideFetchRowDataFn = dataTableDefaultPropValues.serverSideFetchRowDataFn as DataTableProps<RowData>['serverSideFetchRowDataFn'],
  ExpandedTableRow = dataTableDefaultPropValues.ExpandedTableRow as DataTableProps<RowData>['ExpandedTableRow'],
  determineExpandableRow = dataTableDefaultPropValues.determineExpandableRow,
  onRowClickLeft = dataTableDefaultPropValues.onRowClickLeft as DataTableProps<RowData>['onRowClickLeft'],
  enableContextMenu = dataTableDefaultPropValues.enableContextMenu,
  CustomEmptyStateComponent = dataTableDefaultPropValues.CustomEmptyStateComponent as DataTableProps<RowData>['CustomEmptyStateComponent'],
  loader = dataTableDefaultPropValues.loader,
  onStateChanged = dataTableDefaultPropValues.onStateChanged as DataTableProps<RowData>['onStateChanged'],
  onSelectedRowsChanged = dataTableDefaultPropValues.onSelectedRowsChanged as DataTableProps<RowData>['onSelectedRowsChanged'],
  ref = dataTableDefaultPropValues.ref as DataTableProps<RowData>['ref'],
}: DataTableProps<RowData>): ReactElement {
  return <></>;
}

// ====================================
// 4. Export DataTable Type Aliases
// ====================================
/** `DataTableType` is the alias for `props.tableType` */
export type DataTableType = DataTableDefaultPropValues<unknown>['tableType'];
/** `DataTableLayout` is the alias for `props.tableLayout` */
export type DataTableLayout = DataTableDefaultPropValues<unknown>['tableLayout'];
/** `DataTableWrapHeaderStyle` is the alias for `props.tableWrapHeaderStyle` */
export type DataTableWrapHeaderStyle = DataTableDefaultPropValues<unknown>['tableWrapHeaderStyle'];
/** `DataTableEntityName` is the alias for `props.entityName` */
export type DataTableEntityName = DataTableDefaultPropValues<unknown>['entityName'];
/** `DataTableEntityPrefix` is the alias for `props.entityName.prefix` */
export type DataTableEntityPrefix = DataTableDefaultPropValues<unknown>['entityName']['prefix'];
/** `DataTableLastDataUpdateTimeInTableTitle` is the alias for `props.lastDataUpdateTimeInTableTitle` */
export type DataTableLastDataUpdateTimeInTableTitle = DataTableDefaultPropValues<unknown>['lastDataUpdateTimeInTableTitle']; // prettier-ignore
/** `DataTableCsvDownload` is the alias for `props.csvDownload` */
export type DataTableCsvDownload = DataTableDefaultPropValues<unknown>['csvDownload'];
/** `DataTableFetchCSVFn` is the alias for `props.csvDownload.fetchCSV` */
export type DataTableFetchCSVFn = DataTableDefaultPropValues<unknown>['csvDownload']['fetchCSV'];
/** `DataTableServerSideFetchRowDataFn` is the alias for `props.serverSideFetchRowDataFn` */
export type DataTableServerSideFetchRowDataFn<RowData> = DataTableDefaultPropValues<RowData>['serverSideFetchRowDataFn']; // prettier-ignore
/** `DataTableOnRowClickLeft` is the alias for `props.onRowClickLeft` */
export type DataTableOnRowClickLeft<RowData> = DataTableDefaultPropValues<RowData>['onRowClickLeft'];
/** `DataTableClickLeftOptions` is the alias for the string options of `props.onRowClickLeft` */
export type DataTableClickLeftOptions = Extract<DataTableOnRowClickLeft<unknown>, string>; // exclude function type from DataTableOnRowClickLeft type
/** `DataTableQuickFilter` is the alias for `props.quickFilter` */
export type DataTableQuickFilter<RowData> = DataTableDefaultPropValues<RowData>['quickFilter'];
/** `DataTableQuickFilterLayout` is the alias for `props.quickFilter.layout` */
export type DataTableQuickFilterLayout = DataTableDefaultPropValues<unknown>['quickFilter']['layout'];
/** `DataTableClientSideQuickFilterFn` is the alias for `props.quickFilter.options[number].clientSideFilterFn` */
export type DataTableClientSideQuickFilterFn<RowData> = DataTableDefaultPropValues<RowData>['quickFilter']['options'][number]['clientSideFilterFn']; // prettier-ignore
/** `DataTableServerSideQuickFilterQueryParamFn` is the alias for `props.quickFilter.options[number].serverSideQueryParamFn` */
export type DataTableServerSideQuickFilterQueryParamFn<RowData> = DataTableDefaultPropValues<RowData>['quickFilter']['options'][number]['serverSideQueryParamFn']; // prettier-ignore
/** `DataTableFilters` is the alias for `props.filters` */
export type DataTableFilters<RowData> = DataTableDefaultPropValues<RowData>['filters'];
/** `DataTableBulkEdit` is the alias for `props.bulkEdit` */
export type DataTableBulkEdit<RowData> = DataTableDefaultPropValues<RowData>['bulkEdit'];
/** `DataTableBulkEditLayout` is the alias for `props.bulkEdit.layout` */
export type DataTableBulkEditLayout = DataTableDefaultPropValues<unknown>['bulkEdit']['layout'];
/** `DataTableBulkEditTitleFn` is the alias for `props.bulkEdit.title` */
export type DataTableBulkEditTitleFn = DataTableDefaultPropValues<unknown>['bulkEdit']['title'];
/** `DataTableClientSideEditRowInPlaceFn` is the alias for `props.bulkEdit.options[number].clientSideEditRowInPlaceFn` */
export type DataTableClientSideEditRowInPlaceFn<RowData> = DataTableDefaultPropValues<RowData>['bulkEdit']['options'][number]['clientSideEditRowInPlaceFn']; // prettier-ignore
/** `DataTableServerSideEditRowsAsyncFn` is the alias for `props.bulkEdit.options[number].serverSideEditRowsAsyncFn` */
export type DataTableServerSideEditRowsAsyncFn<RowData> = DataTableDefaultPropValues<RowData>['bulkEdit']['options'][number]['serverSideEditRowsAsyncFn']; // prettier-ignore
/** `DataTableBulkEditConfirmModalFn` is the alias for `props.bulkEdit.confirmModal` */
export type DataTableBulkEditConfirmModalFn = DataTableDefaultPropValues<unknown>['bulkEdit']['confirmModal'];
/** `DataTableBulkEditCompleteModalFn` is the alias for `props.bulkEdit.completeModal` */
export type DataTableBulkEditCompleteModalFn = DataTableDefaultPropValues<unknown>['bulkEdit']['completeModal'];
/** `DataTableBulkEditCompleteToastFn` is the alias for `props.bulkEdit.completeToast` */
export type DataTableBulkEditCompleteToastFn = DataTableDefaultPropValues<unknown>['bulkEdit']['completeToast'];
/** `DataTableEditTableButton` is the alias for `props.editTableButton` */
export type DataTableEditTableButton<RowData> = DataTableDefaultPropValues<RowData>['editTableButton'];
/** `DataTableServerSideSaveMultipleRowsFn` is the alias for `props.editTableButton.serverSideSaveMultipleRowsFn` */
export type DataTableServerSideSaveMultipleRowsFn<RowData> = DataTableDefaultPropValues<RowData>['editTableButton']['serverSideSaveMultipleRowsFn']; // prettier-ignore
/** `DataTableOnRowsSaved` is the alias for `props.editTableButton.onRowsSaved` */
export type DataTableOnRowsSaved<RowData> = DataTableDefaultPropValues<RowData>['editTableButton']['onRowsSaved'];
/** `DataTablePagination` is the alias for `props.pagination` */
export type DataTablePagination<RowData> = DataTableDefaultPropValues<RowData>['pagination'];
/** `DataTablePaginationLayout` is the alias for `props.pagination.layout` */
export type DataTablePaginationLayout = DataTableDefaultPropValues<unknown>['pagination']['layout'];
/** `DataTablePaginationLocation` is the alias for `props.pagination.paginatorLocation` */
export type DataTablePaginationLocation = DataTableDefaultPropValues<unknown>['pagination']['paginatorLocation'];
/** `DataTableDetermineSelectableRow` is the alias for `props.pagination.determineSelectableRow` */
export type DataTableDetermineSelectableRow<RowData> = DataTableDefaultPropValues<RowData>['determineSelectableRow'];
/** `DataTableOnRenderRowProps` is the alias for `props.onRenderRowProps` */
export type DataTableOnRenderRowProps<RowData> = DataTableDefaultPropValues<RowData>['onRenderRowProps'];
/** `DataTableDetermineExpandableRow` is the alias for `props.determineExpandableRow` */
export type DataTableDetermineExpandableRow<RowData> = DataTableDefaultPropValues<RowData>['determineExpandableRow'];

/** @deprecated DataTableStateColumn is deprecated. Use DataTableColumn instead */
export type DataTableStateColumn<RowData, FilterConditionValue = unknown> = DataTableColumn<RowData, FilterConditionValue>; // prettier-ignore

// ====================================
// 5. DataTable PropTypes
//    Dev Note: some columns and editTableButton types are added with `null` to make prop-types validation easier.
// ====================================
type DataTablePropTypes<RowData> = WeakValidationMap<
  // Redefines columns to allow nulls
  // The nulls are only necessary for compatibility with prop-types. They are treated the same as `undefined`.
  Omit<DataTableProps<RowData>, 'columns' | 'editTableButton'> & {
    columns: AddNull<
      DataTableColumn<RowData>,
      | 'headerTooltip'
      | 'headerAlignRight'
      | 'columnFilter'
      | 'configOptionName'
      | 'defaultHideColumn'
      | 'clientSideFullTextSearch'
      | 'clientSideCsvDownload'
    >[];

    // Redefines editTableButton to allow nulls
    // The nulls are only necessary for compatibility with prop-types. They are treated the same as `undefined`.
    editTableButton?: AddNull<DataTableEditTableButton<RowData>, 'saveAlerts' | 'cancelAlerts'>;
  }
>;

type SimpleRowData = unknown;
type SimpleFilterConditionValue = unknown;
const dataTablePropTypes: DataTablePropTypes<SimpleRowData> = {
  tableType: PropTypes.oneOf<DataTableType>(['client-side', 'server-side']).isRequired,
  className: PropTypes.string,
  tableLayout: PropTypes.oneOf<DataTableLayout>(['compact', 'medium']),
  tableShowVerticalDividers: PropTypes.bool,
  tableStickyHeaders: PropTypes.bool,
  tableWrapHeaderStyle: PropTypes.oneOf<DataTableWrapHeaderStyle>(['wrap', 'nowrap']),
  grayDeletedRows: PropTypes.bool,
  title: PropTypes.string,
  entityName: PropTypes.shape({
    showInTableTitle: PropTypes.bool.isRequired,
    showInControlPanel: optionalBoolValidator,
    prefix: PropTypes.oneOf<DataTableEntityPrefix>(['', 'Approximately', 'More Than']).isRequired,
    singular: PropTypes.string.isRequired,
    plural: PropTypes.string.isRequired,
  }),
  lastDataUpdateTimeInTableTitle: PropTypes.shape({
    show: PropTypes.bool.isRequired,
    updateTime: PropTypes.instanceOf(Date).isRequired,
  }),
  showServerSideRefreshButton: PropTypes.bool,
  csvDownload: PropTypes.shape({
    enableDownloadOptions: optionalBoolValidator,
    showButton: PropTypes.bool.isRequired,
    buttonText: optionalStringValidator,
    downloadFilePrefix: optionalStringValidator,
    fetchCSV: optionalFunctionValidator<DataTableFetchCSVFn>(),
  }),
  quickFilter: PropTypes.shape({
    layout: PropTypes.oneOf<DataTableQuickFilterLayout>(['none', 'one-line', 'multi-lines', 'multi-select']).isRequired,
    showAllOptions: optionalBoolValidator,
    showAllOptionsButton: optionalBoolValidator,
    selectedOptionIds: PropTypes.arrayOf(PropTypes.string.isRequired),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.oneOfType([
          PropTypes.string.isRequired,
          PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
        ]).isRequired,
        clientSideFilterFn: optionalFunctionValidator<DataTableClientSideQuickFilterFn<SimpleRowData>>(),
        serverSideQueryParamFn: optionalFunctionValidator<DataTableServerSideQuickFilterQueryParamFn<SimpleRowData>>(),
        isOptional: optionalBoolValidator,
      }).isRequired
    ).isRequired,
  }),
  filterHideable: PropTypes.bool,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      filterId: PropTypes.string.isRequired,
      filterLabel: PropTypes.string.isRequired,
      clientSideFilterFn: optionalFunctionValidator<DataTableClientSideFilterFn<SimpleRowData, SimpleFilterConditionValue>>(), // prettier-ignore
      serverSideQueryParamFn: optionalFunctionValidator<DataTableServerSideQueryParamFn<SimpleFilterConditionValue>>(),
      describeConditionValue: requiredFunctionValidator<DataTableFilterDescribeConditionValue<SimpleFilterConditionValue>>(), // prettier-ignore
      DataFilterComponent: optionalFunctionValidator<React.ComponentType<DataTableFilterComponentProps<SimpleRowData, SimpleFilterConditionValue>>>(), // prettier-ignore
    }).isRequired
  ),
  bulkEdit: PropTypes.shape({
    layout: PropTypes.oneOf<DataTableBulkEditLayout>(['none', 'button', 'menu', 'row']).isRequired,
    title: optionalFunctionValidator<DataTableBulkEditTitleFn>(),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        clientSideEditRowInPlaceFn: optionalFunctionValidator<DataTableClientSideEditRowInPlaceFn<SimpleRowData>>(),
        serverSideEditRowsAsyncFn: optionalFunctionValidator<DataTableServerSideEditRowsAsyncFn<SimpleRowData>>(),
      }).isRequired
    ).isRequired,
    confirmModal: optionalFunctionValidator<DataTableBulkEditConfirmModalFn>(),
    completeModal: optionalFunctionValidator<DataTableBulkEditCompleteModalFn>(),
    completeToast: optionalFunctionValidator<DataTableBulkEditCompleteToastFn>(),
  }),
  editTableButton: PropTypes.shape({
    show: PropTypes.bool.isRequired,
    showSaveAndCancelButtons: PropTypes.bool.isRequired,
    serverSideSaveMultipleRowsFn: optionalFunctionValidator<DataTableServerSideSaveMultipleRowsFn<SimpleRowData>>(),
    onRowsSaved: optionalFunctionValidator<DataTableOnRowsSaved<SimpleRowData>>(),
    saveAlerts: PropTypes.shape({
      confirmModal: optionalFunctionValidator<DataTableSingleEditConfirmModalFn<SimpleRowData>>(),
      completeModal: optionalFunctionValidator<DataTableSingleEditCompleteModalFn<SimpleRowData>>(),
      completeToast: optionalFunctionValidator<DataTableSingleEditCompleteToastFn<SimpleRowData>>(),
    }),
    cancelAlerts: PropTypes.shape({
      confirmModal: optionalFunctionValidator<DataTableSingleEditConfirmModalFn<SimpleRowData>>(),
      completeModal: optionalFunctionValidator<DataTableSingleEditCompleteModalFn<SimpleRowData>>(),
      completeToast: optionalFunctionValidator<DataTableSingleEditCompleteToastFn<SimpleRowData>>(),
    }),
  }),
  showDeleteRowCheckbox: PropTypes.bool,
  showSearchBox: PropTypes.bool,
  showColumnConfigButton: PropTypes.bool,
  pagination: PropTypes.shape({
    layout: PropTypes.oneOf<DataTablePaginationLayout>(['none', 'default', 'compact', 'withPageJump']).isRequired,
    pageIndex: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired, // show 'All Rows' if pageSize <= 0
    pageSizeOptions: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    paginatorLocation: PropTypes.oneOf<DataTablePaginationLocation>(['top-right', 'bottom-left', 'top-and-bottom'])
      .isRequired,
    serverSideTotalCount: PropTypes.number,
  }),
  showColumnFilters: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      columnId: PropTypes.string.isRequired,
      headerTooltip: PropTypes.node,
      headerAlginRight: PropTypes.bool,
      Header: requiredFunctionValidator<React.ComponentType<DataTableHooksAndColumnId<SimpleRowData>>>(),
      Footer: requiredFunctionValidator<React.ComponentType<DataTableHooksAndColumnId<SimpleRowData>>>(),
      Cell: requiredFunctionValidator<React.ComponentType<DataTableHooksAndRowId<SimpleRowData>>>(),
      EditCell: optionalFunctionValidator<React.ComponentType<DataTableHooksAndRowId<SimpleRowData>>>(),
      DeleteCell: optionalFunctionValidator<React.ComponentType<DataTableHooksAndRowId<SimpleRowData>>>(),
      determineCellAlert: optionalFunctionValidator<DataTableDetermineCellAlert<SimpleRowData>>(),
      determineCellTooltip: optionalFunctionValidator<DataTableDetermineCellTooltip<SimpleRowData>>(),
      dataValidator: optionalFunctionValidator<DataTableDataValidator<SimpleRowData>>(),
      clientSideSortAscFn: optionalFunctionValidator<DataTableClientSideSortAscFn<SimpleRowData>>(),
      clientSideSortFn: optionalFunctionValidator<DataTableClientSideSortFn<SimpleRowData>>(),
      columnFilter: PropTypes.shape({
        clientSideColumnFilterFn: optionalFunctionValidator<DataTableClientSideFilterFn<SimpleRowData, SimpleFilterConditionValue>>(), // prettier-ignore
        serverSideQueryParamFn: optionalFunctionValidator<DataTableServerSideQueryParamFn<SimpleFilterConditionValue>>(), // prettier-ignore
        ColumnFilterComponent: optionalFunctionValidator<React.ComponentType<DataTableColumnFilterProps<SimpleRowData, SimpleFilterConditionValue>>>(), // prettier-ignore
      }),
      configOptionName: PropTypes.string,
      defaultHideColumn: PropTypes.bool,
      clientSideFullTextSearch: PropTypes.shape({
        enable: PropTypes.bool.isRequired,
        getTextByRow: requiredFunctionValidator<DataTableGetTextByRow<SimpleRowData>>(),
      }),
      clientSideCsvDownload: PropTypes.shape({
        enable: PropTypes.bool.isRequired,
        headerText: PropTypes.string.isRequired,
        getTextByRow: requiredFunctionValidator<DataTableGetTextByRow<SimpleRowData>>(),
      }),
    }).isRequired
  ).isRequired,
  serverSideFetchRowDataFn: optionalFunctionValidator<DataTableServerSideFetchRowDataFn<SimpleRowData>>(),
  onRowClickLeft: PropTypes.oneOfType([
    requiredFunctionValidator<DataTableOnRowClickLeft<SimpleRowData>>(),
    PropTypes.oneOf<DataTableClickLeftOptions>(['toggle-select-row', 'toggle-expand-row']),
  ]),
  enableContextMenu: PropTypes.bool,
  loader: PropTypes.shape({
    show: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired,
  }),
};

// ====================================
// 6. Export DataTable
// ====================================
DataTable.propTypes = dataTablePropTypes;
DataTable.displayName = 'DataTable';
export default DataTable;
